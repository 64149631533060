export const featuredPortfolio = [
  {
    id: 1,
    title: "Social Media App",
    img:"assets/fernando_underconstruction.jpg",
  },
  {
    id: 2,
    title: "Pizza store App",
    img:"assets/fernando_underconstruction.jpg",
  },
  {
    id: 3,
    title: "card game App",
    img:"assets/fernando_underconstruction.jpg",
  },
  {
    id: 4,
    title: "other App",
    img:"assets/fernando_underconstruction.jpg",
  }
];

export const webPortfolio = [
  {
    id: 1,
    title: "Social Media App",
    img:"assets/coming_soon.jpg",
  },
  {
    id: 2,
    title: "Pizza store App",
    img:"assets/coming_soon.jpg",
  },
  {
    id: 3,
    title: "card game App",
    img:"assets/coming_soon.jpg",
  }
];

